import Handlebars from 'handlebars';

(function () {

    'use strict';

    const PRODUCTS = 'products';
    const CONTENT = 'content';
    const CARDS = 12;

    const component = document.querySelector('#daikin-general-search');

    if (!component) {
        return;
    }

    component.classList.add('search--products');

    // General listener for all search elements
    component.addEventListener('click', (event) => {
        let closestHandler = event.target.closest('[data-handler]');
        if (closestHandler) {
            handlers[closestHandler.dataset.handler](event);
        }
    });

    let imgErrorHandler = (event) => {
        event.target.closest('.result-list__image-wrapper')?.classList.add('search-img-fallback');
    }

    const handlers = {

        page: (event) => {
            let page = event.target.closest('[data-page]').dataset.page;

            let stateObj = state.tab === PRODUCTS ? state.products : state.articles;

            if (page === 'next') page = stateObj.currentPage + 1;
            if (page === 'prev') page = stateObj.currentPage - 1;
            if (page === 'first') page = 1;
            if (page === 'last') page = stateObj.lastPage;
        
            setState(stateObj, {
                currentPage: page,
                inFirstPage: page === 1,
                inLastPage: page === stateObj.lastPage,
                inMiddlePage: page !== 1 && page !== stateObj.lastPage,
                disablePaginationNextButton: page === stateObj.lastPage,
                disablePaginationPrevButton: page === 1,
                showMiddlePage: stateObj.lastPage > 2,
                middlePage: page !== 1 && page !== stateObj.lastPage ? page : false
            }, [`${state.tab === PRODUCTS ? 'product' : 'content'}-pagination`]);
            solrCall(state.tab === PRODUCTS ? PRODUCTS : CONTENT, false, page);
            document.querySelector('#daikin-general-search-header').scrollIntoViewIfNeeded();
     
        },

        tab: (event) => {
            const isProducts = event.target.closest('[data-tab]').dataset.tab === PRODUCTS;
            const isContent = event.target.closest('[data-tab]').dataset.tab === CONTENT;

            if (isProducts) {
                component.classList.add('search--products');
                component.classList.remove('search--content');
            }

            if (isContent) {
                component.classList.add('search--content');
                component.classList.remove('search--products');
            }

            setState(state, {
                tab: isProducts ? PRODUCTS : CONTENT,
                isProducts: isProducts,
                isContent: isContent
            }, ['tabs']);
        },

        faqsShowMore: (event) => {
            const faqAccordion = event.target.closest('#daikin-general-search-results-faq');
            const faqCollapseBtn = faqAccordion && faqAccordion.querySelector('.faq-showmore');
            faqAccordion.classList.toggle('search-faqs--short');
            faqCollapseBtn.classList.toggle('faq-showmore--less');
            document.querySelector('#daikin-general-search-results-faq').scrollIntoViewIfNeeded();
        },

        accordionItem: (event) => {
            event.target.querySelector('.cmp-accordion__panel').classList.toggle('cmp-accordion__panel--hidden');
            event.target.querySelector('.cmp-accordion__icon').classList.toggle('open');
        }

    };

    // State with initial values
    const state = {
        
        // Set PRODUCTS as default tab
        tab: PRODUCTS,
        isProducts: true,
        isContent: false,

        products: {
            count: 0,
            results: [],
            currentPage: 1,
            showMiddlePage: true,
            middlePage: false,
            lastPage: 4,
            disablePaginationNextButton: false,
            disablePaginationPrevButton: false
        },

        articles: {
            count: 0,
            results: [],
            currentPage: 1,
            showMiddlePage: true,
            middlePage: false,
            lastPage: 4,
            disablePaginationNextButton: false,
            disablePaginationPrevButton: false
        },

        faqs: {
            count: 0,
            results: [],
        },
        
        content: {
            count: 0
        },

    };

    window.search = state;

    function setState(stateObj, newState, blocks) {
        Object.assign(stateObj, newState);
        render(blocks);
    }

    function render(blocks) {

        console.log('re-render');

        // List rendering
        let listTemplateNode = document.querySelector('template#search-card-template').innerHTML;
        let listHandlebarsTemplate = Handlebars.compile(listTemplateNode);
        let listRange = document.createRange();
        // For Content
        if (blocks.includes('content-list')) {
            let listFragmentContent = listRange.createContextualFragment(listHandlebarsTemplate(state.articles.results));
            document.querySelector('#content-main-section .new-card-list').innerHTML = '';
            document.querySelector('#content-main-section .new-card-list').appendChild(listFragmentContent);
            document.querySelectorAll('#content-main-section .new-card-list img').forEach((img) => {
                img.addEventListener('error', imgErrorHandler);
            });    
        }
        // For Products
        if (blocks.includes('product-list')) {
            let listFragmentProducts = listRange.createContextualFragment(listHandlebarsTemplate(state.products.results));
            document.querySelector('#product-main-section .new-card-list').innerHTML = '';
            document.querySelector('#product-main-section .new-card-list').appendChild(listFragmentProducts);
            document.querySelectorAll('#product-main-section .new-card-list img').forEach((img) => {
                img.addEventListener('error', imgErrorHandler);
            });    
        }

        // Pagination rendering
        let paginationTemplateNode = document.querySelector('template#search-pagination-template').innerHTML;
        let paginationHandlebarsTemplate = Handlebars.compile(paginationTemplateNode);
        let paginationRange = document.createRange();
        // For Content
        if (blocks.includes('content-pagination')) {
            let paginationFragmentContent = paginationRange.createContextualFragment(paginationHandlebarsTemplate(state.tab === PRODUCTS ? state.products : state.articles));
            document.querySelector('#content-main-section .new-pagination').innerHTML = '';
            document.querySelector('#content-main-section .new-pagination').appendChild(paginationFragmentContent);    
        }
        // For Products
        if (blocks.includes('product-pagination')) {
            let paginationFragmentProducts = paginationRange.createContextualFragment(paginationHandlebarsTemplate(state.tab === PRODUCTS ? state.products : state.articles));
            document.querySelector('#product-main-section .new-pagination').innerHTML = '';
            document.querySelector('#product-main-section .new-pagination').appendChild(paginationFragmentProducts);    
        }

        // Tabs rendering
        if (blocks.includes('tabs')) {
            let tabsTemplateNode = document.querySelector('template#search-tabs-template').innerHTML;
            let tabsHandlebarsTemplate = Handlebars.compile(tabsTemplateNode);
            let tabsRange = document.createRange();
            let tabsFragment = tabsRange.createContextualFragment(tabsHandlebarsTemplate(state));
            document.querySelector('.new-tabs').innerHTML = '';
            document.querySelector('.new-tabs').appendChild(tabsFragment);    
        }

        // Faqs rendering
        if (blocks.includes('faqs-list')) {
            let faqsTemplateNode = document.querySelector('template#search-faqs-template').innerHTML;
            let faqsHandlebarsTemplate = Handlebars.compile(faqsTemplateNode);
            let faqsRange = document.createRange();
            let faqsFragment = faqsRange.createContextualFragment(faqsHandlebarsTemplate(state.faqs));
            document.querySelector('.new-faqs').innerHTML = '';
            document.querySelector('.new-faqs').appendChild(faqsFragment);
        }

    }

    function isMobile() {
        return window.matchMedia("(max-width: 576px)").matches;
    }
    
    const boldQuery = (str, query) => {
        const n = str.toUpperCase();
        const q = query.toUpperCase();
        const x = n.indexOf(q);
        if (!q || x === -1) {
            return str;
        }
        const l = q.length;
        return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
    }

    function autocomplete(inp) {
        
        var currentFocus;
        var timer;
        inp.addEventListener("input", function(e) {
            var a, b, i, val = this.value;          
            currentFocus = -1;
            closeAllLists();
            if (!val || (val && val.length < 2)) {
                return false;
            }

            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            this.parentNode.appendChild(a);
            
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(async function(){
                let autocompleteResult = await solrAutocomplete();

                for (i = 0; i < autocompleteResult.length; i++) {
                    if (autocompleteResult[i].toUpperCase().includes(val.toUpperCase())) {
                    b = document.createElement("DIV");
                    b.innerHTML = boldQuery(autocompleteResult[i], val);        
                    b.innerHTML += "<input type='hidden' value='" + autocompleteResult[i] + "'>";
                    b.addEventListener("click", function(e) {
                        inp.value = this.getElementsByTagName("input")[0].value;                
                        closeAllLists();
                        newSearch();
                    });
                    a.appendChild(b);
                    }
                }
            }, 100);            
            
        });

        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                currentFocus++;
                addActive(x);
            } else if (e.keyCode == 38) {
                currentFocus--;
                addActive(x);
            } else if (e.keyCode == 13) {
                if (currentFocus > -1) {
                    if (x) x[currentFocus].click();
                } else {
                    closeAllLists();
                }
            }
        });
      
        function addActive(x) {
            if (!x) return false;
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            x[currentFocus].classList.add("autocomplete-active");
        }
      
        function removeActive(x) {
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
      
        function closeAllLists(elmnt) {
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
        });
    }

    function getQueryParam() {
        const searchInput = document.querySelector('.cmp-general-search__box input[type=search]');
        if (searchInput && searchInput.value !== '') {
            return searchInput.value;
        } else {
            return null;
        }
    }

    function getFilters(type) {
        let response = '';
        let checkBoxesChecked = null;
        if (type === 'content') {
            checkBoxesChecked = document.querySelectorAll('#content-filter-rail-list input[name=content-checkbox]:checked');

            let subTypesChecked = document.querySelectorAll('#content-filter-rail-list input[name=subtype-checkbox]:checked');
            if (subTypesChecked.length > 0) {
                [...subTypesChecked].forEach((item) => {
                    response = response + '&' + item.id.replace('__', '=');
                });
            }

            let sortByChecked = document.querySelector('#content-filter-rail-list input[type=radio]:checked');
            if (sortByChecked) {
                response = response + '&' + sortByChecked.id.replace('__', '=');
            }
        } else {
            checkBoxesChecked = document.querySelectorAll('#product-filter-rail-list input[name=product-checkbox]:checked');
        }

        if (checkBoxesChecked.length > 0) {
            [...checkBoxesChecked].forEach((item) => {
                response = response + '&f.facet_' + item.id.replace('____', '=').replace('__', '=');
            });
        }

        return response;
    }

    function updateFilterCount() {
        const type = getCurrentType();
        const openFilter = document.querySelector('#' + type + '-filter-rail-list .facet-filter__buttonOpen .facet-filter__text');
        const originalText = openFilter.getAttribute('data-cmp-facet');
        const filterCount = document.querySelectorAll('#' + type + '-filter-rail-list .facet-filter:has(.facet-filter__buttonOpen)+.checkbox-list-wrapper .checkbox-list input:checked').length;
        if (filterCount > 0) {
            openFilter.innerText = originalText + ' (' + filterCount + ')';
        } else {
            openFilter.innerText = originalText;
        }
    }

    function clearFilters() {
        const visibleFilters = document.querySelectorAll('#' + getCurrentType() + '-filter-rail-list .facet-filter:has(.facet-filter__buttonOpen)+.checkbox-list-wrapper .checkbox-list input');
        if (visibleFilters.length) {
            [...visibleFilters].forEach(i => i.checked = false);
        }
        updateFilterCount();
        solrCall();
    }

    function clearFiltersMobile() {
        const currentType = getCurrentType();
        const visibleFilters = document.querySelectorAll('#' + currentType + '-filter-rail-list input:checked');
        if (visibleFilters.length) {
            [...visibleFilters].forEach(i => i.checked = false);
        }
        const allFacets = document.querySelectorAll('#' + currentType + '-filter-rail-list .facet-filter > button > span');
        if (allFacets.length) {
            [...allFacets].forEach(f => f.innerText = f.getAttribute('data-cmp-facet'));
        }
    }

    function toggleFiltersMobile() {
        const currentRail = getCurrentType() === 'content' ? document.getElementById('content-filter-rail-list') : document.getElementById('product-filter-rail-list');
        currentRail.classList.toggle('filter-rail__mobile');
        if (currentRail.classList.contains('filter-rail__mobile') !== true) {
            var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
            while (filtersOpened.length) {
                filtersOpened[0].classList.remove("facet-filter__buttonOpen");
            }
        }
    }


    function getCurrentType() {
        return document.getElementsByClassName('btn-product').length === 0 ? 'content' : 'product';
    }




    function navigateTo(path) {
        if (path.indexOf('html') === -1) path += '.html';
        window.location.assign(path);
    }

    function truncate(str, n) {
        if (str.length <= n) { return str; }
        const subString = str.substr(0, n - 1);
        return subString.substr(0, subString.lastIndexOf(" ")) + "...";
    }

    function removeMobileClass() {
        if (((document.getElementById('product-filter-rail-list').classList.contains('filter-rail__mobile')) && isMobile() !== true) || ((document.getElementById('content-filter-rail-list').classList.contains('filter-rail__mobile')) && isMobile() !== true)) {
            var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
            while (filtersOpened.length) {
                filtersOpened[0].classList.remove("facet-filter__buttonOpen");
            }
        }
        if (isMobile() !== true) {
            document.getElementById('content-filter-rail-list').classList.remove('filter-rail__mobile');
            document.getElementById('product-filter-rail-list').classList.remove('filter-rail__mobile');
        }
    }

    function removeDesktopClass() {
        document.querySelectorAll('.facet-filter button[disabled]')?.forEach(btn => btn.disabled = false);
        var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
        while (filtersOpened.length) {
            filtersOpened[0].classList.remove("facet-filter__buttonOpen");
        }
        let clearFilters = document.getElementsByClassName("filter-rail-clear");
        [...clearFilters].forEach((elem) => { elem.style.display = 'none' });
    }

    function handleResize() {
        if (isMobile()) {
            removeDesktopClass();
        } else {
            removeMobileClass();
        }
    }

    function newSearch() {
        solrCall('product', false, 1);
        solrCall('content', true, 1); // FAQS
        solrCall('content', false, 1);
    }

    function createArrowSvg() {
        const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        iconSvg.setAttribute('width', '32');
        iconSvg.setAttribute('height', '33');
        iconSvg.setAttribute('viewBox', '0 0 32 33');
        iconSvg.setAttribute('fill', 'none');
        iconPath.setAttribute('d', 'M21.9603 9.55762L20.547 10.971L25.507 15.931H2.66699V17.931H25.507L20.547 22.891L21.9603 24.3043L29.3337 16.931L21.9603 9.55762Z');
        iconSvg.appendChild(iconPath);
        return iconSvg;
    }

    function getImageSrc(cardImg) {
        let imgSrc = '/content/dam/DACI-Internet/placeholder/placeholder_34.png';
        if (typeof cardImg !== 'undefined') {
            imgSrc = cardImg;
        }
        return imgSrc + '/_jcr_content/renditions/cq5dam.thumbnail.319.319.png';
    }

    function createProductCardBody(parent, card) {
        const cardImg = parent.querySelector('img');
        cardImg.setAttribute('src', getImageSrc(card.variant_1_product_shot));
        let cardTitle = document.createElement('h3');
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.product_name;
        // cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        let cardDescription = document.createElement('p');
        cardDescription.classList.add('result-list__description');
        cardDescription.innerText = truncate(card.product_description, 120);
        // cardDescription.addEventListener('click', function () { navigateTo(card.path) });

        let cardFooter = document.createElement('div');
        cardFooter.classList.add('result-list__color');
        let totalColors = Object.keys(card).filter(value => /variant_[1-5]_id/.test(value)).length;
        if (totalColors > 1) {
            let mainColor = document.createElement('img');
            mainColor.classList.add('result-list__color__circle');
            mainColor.src = card.variant_1_swatch_image;
            let otherColors = document.createElement('p');
            otherColors.classList.add('result-list__color__text');
            let generalSearch = document.getElementById('daikin-general-search');
            let otherColorsLabel = generalSearch.getAttribute('data-cmp-label-colors');
            if (totalColors === 2) {
                otherColorsLabel = generalSearch.getAttribute('data-cmp-label-color');
            }
            otherColors.innerText = otherColorsLabel.replace('{0}', totalColors - 1);
            cardFooter.appendChild(mainColor);
            cardFooter.appendChild(otherColors);
        }

        parent.appendChild(cardTitle);
        parent.appendChild(cardDescription);
        parent.appendChild(cardFooter);

        return parent;
    }

    function createContentCardBody(parent, card) {

        const cardImg = parent.querySelector('img');
        const cardHeading = document.createElement('p');
        const cardTitle = document.createElement('h3');
        const cardFooter = document.createElement('div');

        cardImg.setAttribute('src', getImageSrc(card.featured_image));
        const generalSearchHeader = document.getElementById('daikin-general-search-header');
        if (generalSearchHeader.getAttribute('data-search-subtype') === 'article') {
            cardHeading.classList.add('result-list__heading-article');
            cardHeading.innerText = "";
        }
        else {
            cardHeading.innerText = card.subtype;
        }
        // cardHeading.addEventListener('click', function () { navigateTo(card.path) });
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.title;
        // cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        cardFooter.appendChild(createArrowSvg());
        // cardFooter.addEventListener('click', function () { navigateTo(card.path) });
        if (card.subtype === 'category') {
            parent.classList.add('result-list__category');
            cardHeading.classList.add('result-list__heading');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__cold');
        } else {
            cardHeading.classList.add('result-list__heading-content');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__warm');
            let footerText = document.createElement('p');
            footerText.classList.add('result-list__arrow__text');
            footerText.innerText = document.getElementById('daikin-general-search').getAttribute('data-cmp-label-readmore');
            cardFooter.appendChild(footerText);
        }

        if (card.subtype === 'category') {
            let categoryCardDiv = document.createElement('div');
            categoryCardDiv.classList.add('result-list__info');
            categoryCardDiv.appendChild(cardHeading);
            categoryCardDiv.appendChild(cardTitle);
            categoryCardDiv.appendChild(cardFooter);
            parent.appendChild(categoryCardDiv);
        } else {
            parent.appendChild(cardHeading);
            parent.appendChild(cardTitle);
            parent.appendChild(cardFooter);
        }

        return parent;
    }

    async function solrAutocomplete(type) {
        type = (typeof type !== 'undefined') ? type : getCurrentType();
        let searchResultsId = 'daikin-general-search-results-product';
        if (type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
        }
        const generalSearch = document.getElementById('daikin-general-search');
        if (generalSearch) {
            let subtypeParam = '';
            const generalSearchHeader = document.getElementById('daikin-general-search-header');
            if (generalSearchHeader && generalSearchHeader.getAttribute('data-search-subtype') !== null) {
                subtypeParam = '&subtype=' + generalSearchHeader.getAttribute('data-search-subtype');
            }
            const searchEndpoint = generalSearch.getAttribute('data-cmp-endpoint').replace('.b2cquery.','.b2cautocomplete.');
            const langParam = generalSearch.getAttribute('data-cmp-language');
            const queryParam = getQueryParam() !== null ? '&title=' + getQueryParam() : '';
            //const rows = sessionStorage.getItem('searchRows') !== null ? sessionStorage.getItem('searchRows') : 12;

            let tmpCurrentPage = 1;
            // const offsetParam = '&offset=' + Math.max(((getCurrentPage() - 1) * rows), 0);
            const offsetParam = '&offset=' + Math.max(((tmpCurrentPage - 1) * CARDS), 0);


            try {
                const requestUrl = searchEndpoint + '?language=' + langParam + offsetParam + queryParam + '&type=' + type + subtypeParam + getFilters(type);
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                let autocompleteResult = [];
                if (responseData.results) {                                  
                    [...responseData.results].forEach((card) => {
                        autocompleteResult.push(card.title);
                    });
                }

                return autocompleteResult;

            } catch (error) {
                console.error(error);
            }

        }
    }

    async function solrCall(type, includeFaqs, page) {

        if (type === PRODUCTS) type = 'product';

        // type = (typeof type !== 'undefined') ? type : getCurrentType();
        includeFaqs = (typeof includeFaqs !== 'undefined') ? includeFaqs : false;
        let searchResultsId = 'daikin-general-search-results-product';
        let faqParam = '';
        if (type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
            faqParam = '&faq=' + includeFaqs.toString();
        }
        const generalSearch = document.getElementById('daikin-general-search');
        if (generalSearch) {
            
            let subtypeParam = '';
            // const generalSearchHeader = document.getElementById('daikin-general-search-header');
            
            //if (generalSearchHeader && generalSearchHeader.getAttribute('data-search-subtype') !== null) {
                //subtypeParam = '&subtype=' + generalSearchHeader.getAttribute('data-search-subtype');
            //}
            const searchEndpoint = generalSearch.getAttribute('data-cmp-endpoint');
            const langParam = generalSearch.getAttribute('data-cmp-language');
            const queryParam = getQueryParam() !== null ? '&q=' + getQueryParam() : '';
            // const rows = sessionStorage.getItem('searchRows') !== null ? sessionStorage.getItem('searchRows') : 12;
            
            const offsetParam = '&offset=' + Math.max(((page - 1) * CARDS), 0);
            try {
                const requestUrl = searchEndpoint + '?language=' + langParam + offsetParam + queryParam + '&type=' + type + subtypeParam + faqParam + getFilters(type);
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                if (responseData.results) {

                    let isArticle = type === 'content' && includeFaqs === false;
                    let isProduct = type === 'product';
                    let isFaqs = type === 'content' && includeFaqs === true;

                    if (isArticle && page === 1) {
                        setState(state.articles, {
                            results: responseData.results,
                            count: responseData.total,
                            currentPage: 1,
                            inFirstPage: true,
                            inLastPage: false,
                            inMiddlePage: false,
                            lastPage: Math.ceil(responseData.total / CARDS),
                            disablePaginationNextButton: false,
                            disablePaginationPrevButton: true,
                            showMiddlePage: Math.ceil(responseData.total / CARDS) > 2, // If we have two pages or less, we don't need the middle page
                            middlePage: false
                        }, ['content-list', 'tabs', 'content-pagination']);
                    }

                    if (isArticle && page !== 1) {
                        setState(state.articles, {
                            results: responseData.results,
                            count: responseData.total,
                        }, ['content-list', 'tabs']);
                    }

                    if (isProduct && page === 1) {
                        setState(state.products, {
                            results: responseData.results,
                            count: responseData.total,
                            currentPage: 1,
                            inFirstPage: true,
                            inLastPage: false,
                            inMiddlePage: false,
                            lastPage: Math.ceil(responseData.total / CARDS),
                            disablePaginationNextButton: false,
                            disablePaginationPrevButton: true,
                            showMiddlePage: Math.ceil(responseData.total / CARDS) > 2, // If we have two pages or less, we don't need the middle page
                            middlePage: false
                        }, ['product-list', 'tabs', 'product-pagination']);
                    }

                    if (isProduct && page !== 1) {
                        setState(state.products, {
                            results: responseData.results,
                            count: responseData.total,
                        }, ['product-list', 'tabs']);
                    }

                    if (isFaqs) {
                        setState(state.faqs,{
                            results: responseData.results,
                            count: responseData.total
                        }, ['faqs-list', 'tabs']);
                    }

                    if (isArticle || isFaqs) {
                        setState(state.content, {
                            count: state.articles.count + state.faqs.count
                        }, ['tabs']);
                    }

                }

            } catch (error) {
                console.error(error);
            }

        }
    }



    function checkFilters(filters, item, type) {
        const clearFiltersClass = 'filter-rail-clear__' + type;
        const clearFilters = document.getElementsByClassName(clearFiltersClass);
        if (item.classList.contains('facet-filter__buttonOpen')) {
            [...filters].forEach((elem) => {
                if (isMobile() !== true) {
                    elem.classList.remove("facet-filter__buttonOpen");
                    elem.disabled = false;
                } else {
                    item.classList.remove("facet-filter__buttonOpen");
                }
            });
            [...clearFilters].forEach((elem) => { elem.style.display = 'none' });
        } else {
            [...filters].forEach((elem) => {
                if (item !== elem) {
                    if (isMobile() !== true) {
                        elem.disabled = true;
                    }
                }
            });
            item.classList.add('facet-filter__buttonOpen');
            let display = 'block';
            if (item.classList.contains('facet-sortby') || isMobile() === true) {
                display = 'none';
            }
            [...clearFilters].forEach((elem) => { elem.style.display = display });
        }
    }

    const generalSearchInit = () => {
        document.documentElement.style.setProperty('--filters-column', document.querySelector('#daikin-general-search').dataset.facetsPerColumn);
        const productFilters = document.querySelectorAll('#product-filter-rail-list .facet-filter__button');
        const contentFilters = document.querySelectorAll('#content-filter-rail-list .facet-filter__button');
        const productCheckboxes = document.querySelectorAll('#product-filter-rail-list input[type=checkbox]');
        const contentCheckboxes = document.querySelectorAll('#content-filter-rail-list input[type=checkbox]');
        const searchInput = document.querySelector('.cmp-general-search__box input[type=search]');
        const noResults = document.querySelector('#daikin-general-search-no-results > p');

        if (productFilters.length) {
            [...productFilters].forEach((item) => {
               item.addEventListener('click', () => { checkFilters(productFilters, item, 'product') });
            });
        }

        if (contentFilters.length) {
            [...contentFilters].forEach((item) => {
                item.addEventListener('click', () => { checkFilters(contentFilters, item, 'content') });
            });
        }

        if (searchInput) {
            searchInput.addEventListener('search', newSearch);
        }

        if (productCheckboxes.length) {
            [...productCheckboxes].forEach((item) => {
                item.addEventListener('change', () => {
                   
                    updateFilterCount();
                    if (isMobile() !== true) solrCall('product', false, 1);
                });
            });
        }

        if (contentCheckboxes.length) {
            [...contentCheckboxes].forEach((item) => {
                item.addEventListener('change', () => {
                 
                    updateFilterCount();
                    if (isMobile() !== true) solrCall('content', false, 1);
                });
            });
        }

        const clearFiltersButtons = document.getElementsByClassName('filter-rail-clear');
        if (clearFiltersButtons.length) {
            [...clearFiltersButtons].forEach((item) => {
                item.addEventListener('click', clearFilters);
            });
        }

        window.addEventListener("resize", handleResize);

        const urlParams = new URLSearchParams(window.location.search);
        const urlQuery = urlParams.get('q');
        if (urlQuery) {
            searchInput.value = urlQuery;
        }

        const relevancySortBy = document.getElementById('sortby__relevancy');
        const recencySortBy = document.getElementById('sortby__recency');
        relevancySortBy.checked = searchInput.value !== '';
        recencySortBy.checked = searchInput.value === '';
        if (isMobile() !== true) {
            recencySortBy.checked = searchInput.value === '';
            relevancySortBy.addEventListener('change', () => { solrContentCall() });
            recencySortBy.checked = searchInput.value === '';
            recencySortBy.addEventListener('change', () => { solrContentCall() });
        }

        const searchButton = document.querySelector('.search-icon');
        searchButton.addEventListener('click', newSearch);

        const clearBtn = document.getElementById('general-search-clear-button');
        clearBtn.addEventListener('click', () => {
           searchInput.value = '';
            newSearch();
         });


            /*

                document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-cold-500)');
                document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-cold-200)');

 
                document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-warm-500)');
                document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-warm-200)');

                            if (document.getElementById('content-type-button')) {
                //document.getElementById('content-type-button').classList.toggle('hidden');
            }
            if (document.getElementById('content-type-checkbox-list')) {
                //document.getElementById('content-type-checkbox-list').classList.toggle('hidden');
            }


                */

            const generalSearchHeader = document.getElementById('daikin-general-search-header');
        if (generalSearchHeader.getAttribute('data-search-subtype') === 'article') {
            document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-warm-500)');
            document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-warm-200)');
        }
        const openFiltersMob = document.querySelector('#daikin-general-search .open-filters');
        if (openFiltersMob) {
           // openFiltersMob.addEventListener('click', toggleFiltersMobile);
        }

        const closeFiltersContentMob = document.querySelector('#daikin-general-search .close-filters-content');
        if (closeFiltersContentMob) {
            //closeFiltersContentMob.addEventListener('click', toggleFiltersMobile);
        }

        const closeFiltersProductsMob = document.querySelector('#daikin-general-search .close-filters-products');
        if (closeFiltersProductsMob) {
            //closeFiltersProductsMob.addEventListener('click', toggleFiltersMobile);
        }

        const clearFiltersMob = document.querySelectorAll('.footer-search-menu .secondary-button button');
        if (clearFiltersMob.length) {
            [...clearFiltersMob].forEach((item) => {
                item.addEventListener('click', clearFiltersMobile);
            });
        }

        const applyFiltersProduct = document.querySelector('#product-filter-rail-list .footer-search-menu .primary-button button');
        if (applyFiltersProduct) {
         /*   applyFiltersProduct.addEventListener('click', () => {
                toggleFiltersMobile();
                solrCall('product');
            });*/
        }

        const applyFiltersContent = document.querySelector('#content-filter-rail-list .footer-search-menu .primary-button button');
        if (applyFiltersContent) {
         /*   applyFiltersContent.addEventListener('click', () => {
                toggleFiltersMobile();
                solrContentCall();
            });*/
        }

        

        


        newSearch();
        autocomplete(document.getElementById('general-search-input'));
    };



    if (document.readyState !== 'loading') {
        generalSearchInit();
    } else {
        document.addEventListener('DOMContentLoaded', generalSearchInit);
    }

}());
