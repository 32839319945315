 import Cookies from 'js-cookie';
 import Handlebars from 'handlebars';
 import tippy from 'tippy.js';
 import { getUserCoordinates, getCityName, getDealerCount } from './product-series-dealer-count';
 import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';
 import { COOKIE_ACCEPTED_NAME } from '../cookie/cookie-commons';
 (function () {
     'use strict';
     window.jsB2c = window.jsB2c || {};
     
     async function initializeProductSeriesHeaders() {
         const productSeriesHeader = document.querySelector('.js-product-series-header');
         if (productSeriesHeader) {
             // Product Benefits
             const showMoreBenefitsTrigger = productSeriesHeader.querySelector('.js-product-series-benefits-trigger');
             if (showMoreBenefitsTrigger) {
                 const benefits = showMoreBenefitsTrigger.closest('.js-product-series-header-benefits');
                 const benefitToFocus = benefits.querySelector('.js-product-series-header-benefit:nth-child(3)');
                 showMoreBenefitsTrigger.addEventListener('click', () => {
                     benefits.classList.add('js-product-series-header-benefits-show-all');
                     benefitToFocus.setAttribute('tabindex', '-1');
                     benefitToFocus.focus();
                     showMoreBenefitsTrigger.parentNode.removeChild(showMoreBenefitsTrigger);
                 }, false);
             }
             // Dealer Count
             const dealerCountContainer = productSeriesHeader.querySelector('.js-product-series-dealer-count');
             if (dealerCountContainer && Cookies.get(COOKIE_ACCEPTED_NAME)) {
                 try {
                     const labelSingle = dealerCountContainer.dataset.productSeriesDealerCountLabelSingle;
                     const labelPlural = dealerCountContainer.dataset.productSeriesDealerCountLabelPlural;
                     const radius = dealerCountContainer.dataset.productSeriesDealerLocatorRadius;
                     const dealerLocatorSearchEndpoint = dealerCountContainer.dataset.dealerLocatorSearchEndpoint;
                     const googleApiKey = dealerCountContainer.dataset.googleMapsKey;
                     const position = await getUserCoordinates();
                     const cityName = await getCityName(position, googleApiKey);
                     const amount = await getDealerCount(position, radius, dealerLocatorSearchEndpoint);
                     if (amount && cityName && labelSingle && labelPlural) {
                         const templateData = {
                             'amount': amount,
                             'location': cityName
                         };
                         const templateHandlebars = Handlebars.compile(amount > 1 ? labelPlural : labelSingle);
                         dealerCountContainer.textContent = templateHandlebars(templateData);
                         dealerCountContainer.classList.remove('hidden');
                     }
                 } catch (error) {
                     console.error(error);
                 }
             }
             // Tooltips
             const hideOnEsc = {
                 name: 'hideOnEsc',
                 defaultValue: true,
                 fn({hide}) {
                     function onKeyDown(event) {
                         if (event.keyCode === 27) {
                             hide();
                         }
                     }
                     return {
                         onShow() {
                             document.addEventListener('keydown', onKeyDown);
                         },
                         onHide() {
                             document.removeEventListener('keydown', onKeyDown);
                         },
                     };
                 },
             };
             // Product Series header Tooltip based on selected theme
             let productSeriesTippyTheme;
             if (productSeriesHeader.classList.contains('product-series-header--warm')) {
                 productSeriesTippyTheme = 'b2c-warm';
             } else {
                 productSeriesTippyTheme = 'b2c-cold';
             }
             const productSeriesHeaderTooltips = productSeriesHeader.querySelectorAll('[data-tippy]');
             tippy(productSeriesHeaderTooltips, {
                 plugins: [hideOnEsc],
                 interactive: true,
                 placement: 'top',
                 maxWidth: 260,
                 theme: productSeriesTippyTheme
             });
         }
      
     }
     if (document.readyState !== 'loading') {
         initializeProductSeriesHeaders();
     } else {
         document.addEventListener('DOMContentLoaded', () => {
             initializeProductSeriesHeaders();
         });
     }
     jsB2c.productSeriesHeaders = (function () {
         return {
             init: initializeProductSeriesHeaders,
         };
     })();
 })();