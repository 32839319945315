(function () {

    'use strict';
 
    const init = () => {
        
        const CLASS_SHOW_STICKY = 'cmp-sticky-nav__wrapper--show';
        const CLASS_STICKY_NAV_ITEM = 'sticky-nav__item';
        const CLASS_STICKY_NAV_PRIMARY_BUTTON = 'sticky-nav__primary-button';
        const CLASS_STICKY_NAV_SECONDARY_BUTTON = 'sticky-nav__secondary-button';
       

        const headerComp = document.querySelector('.js-sticky-navigation--enabled');
        const stickyNavWrapper = document.querySelector('.js-cmp-sticky-nav__wrapper');

        if (!headerComp || !stickyNavWrapper) {
            return;
        }

        let stickyNavToParentLink = headerComp.querySelector('.sticky-nav-to-parent-link') || headerComp.querySelector('.cmp-product-series-header__backlink');
        let stickyNavTitle = headerComp.querySelector('.sticky-nav-title') || headerComp.querySelector('.cmp-product-series-header__name');
        let stickyNavSecondaryButton = headerComp.querySelector('.sticky-nav-secondary-button .cmp-button');
        let stickyNavPrimaryButton = headerComp.querySelector('.sticky-nav-primary-button .cmp-button');

        if (!stickyNavPrimaryButton && !stickyNavSecondaryButton) {
            console.log('sticky-nav: No sticky navigation buttons found');
            return;
        }

        let stickyNav = stickyNavWrapper.querySelector('.cmp-sticky-nav');

        let isWarm = document.querySelector('.sticky-nav-primary-button').classList.contains('has-button--style-warm');

        if (stickyNavToParentLink) {
            stickyNav.appendChild(stickyNavToParentLink.cloneNode(true)).classList.add(CLASS_STICKY_NAV_ITEM);
            if (isWarm) {
                stickyNav.querySelector('.sticky-nav-to-parent-link').classList.add('sticky-nav-to-parent-link--warm');
            }
        }

        if (stickyNavTitle) {
            stickyNav.appendChild(stickyNavTitle.cloneNode(true)).classList.add(CLASS_STICKY_NAV_ITEM);
        }

        if (stickyNavSecondaryButton) {
            let div = document.createElement('div');
            div.classList.add('button', 'has-button-link');
            if (isWarm) { 
                div.classList.add('has-button-link--style-warm');
            }
            div.classList.add(CLASS_STICKY_NAV_ITEM, CLASS_STICKY_NAV_SECONDARY_BUTTON);
            div.appendChild(stickyNavSecondaryButton.cloneNode(true));
            stickyNav.appendChild(div);
        }

        if (stickyNavPrimaryButton) {
            let div = document.createElement('div')
            div.classList.add('primary-button', 'button', 'has-button');
            if (isWarm) {
                div.classList.add('has-button--style-warm');
            }
            div.classList.add(CLASS_STICKY_NAV_ITEM, CLASS_STICKY_NAV_PRIMARY_BUTTON);
            div.appendChild(stickyNavPrimaryButton.cloneNode(true));
            stickyNav.appendChild(div);
        }
    
        /**
         * IntersectionObserver to show/hide sticky navigation
         */
        new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        stickyNavWrapper.classList.remove(CLASS_SHOW_STICKY);
                    } else {
                        stickyNavWrapper.classList.add(CLASS_SHOW_STICKY);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: [0]
            }
        ).observe(headerComp);
    }

    if (document.readyState !== 'loading') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }

})();